// eslint-disable-next-line no-restricted-syntax
import { Assets, ResolverBundle } from 'pixi.js';

export type ClassWithType<T> = new (...args: any[]) => T;

export type Class = new (...args: any[]) => any;

// function hasStaticProperty<T extends object>(
//   object: T,
//   property: keyof any,
// ): object is T & { [P in typeof property]: any } {
//   return property in object;
// }

export function assets(bundleId: string, assets: ResolverBundle['assets']) {
  return (target: any) => {
    if (!target.assets) target.assets = {};
    target.assets.assets = assets;
    target.assets.id = bundleId;
  };
}
export function assetBundleItem(bundleId: string, assets: ResolverBundle['assets']) {
  return {
    assets: {
      assets: assets,
      id: bundleId,
    },
  };
}

export function toAssetBundle(target: any): [string, ResolverBundle['assets']] {
  if (!target.assets) throw new Error('no assets metadata found');
  return [target.assets.id, target.assets.assets];
}

export function loadAssetBundles(targets: any, onProgress?: (p: number) => void) {
  const bundles = Array.from(targets).map((t) => toAssetBundle(t));
  bundles.forEach((t) => Assets.addBundle(t[0], t[1]));
  const promise = bundles.map((b) => Assets.loadBundle(b[0]));

  if (onProgress) {
    onProgress(0);
    let d = 0;
    for (const p of promise) {
      p.then(() => {
        d++;
        if (onProgress) onProgress(d / promise.length);
      }).catch((error) => {
        console.log(error);
      });
    }
  }
  return Promise.all(promise);
}

// TODO: it does not support progress for now, removed until it does
// export function unloadAssetBundles<T>(targets: Iterable<Class>, onProgress?: (p: number) => void): Promise<any[]> {
export function unloadAssetBundles(targets: Iterable<Class>): Promise<any[]> {
  return Promise.all(
    Array.from(targets)
      .map((t) => toAssetBundle(t))
      .map((t) => Assets.unloadBundle(t[0]))
  );
}
